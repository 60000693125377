<template>
  <modal
    :height="height"
    :width="width"
    :maxWidth="maxWidth"
    :name="name"
    :scrollable="height === 'auto'"
    v-bind="$attrs"
    v-on="{ ...$listeners }"
  >
    <div class="modal-container" :class="[{ border }, customClass]">
      <slot name="header"
        ><hcc-modal-header v-if="title" :title="title"
      /></slot>
      <hcc-modal-body :class="customClassBody">
        <slot />
      </hcc-modal-body>
    </div>
    <slot name="footer">
      <hcc-modal-footer
        :disabled="confirmDisableButton"
        :handleConfirm="handleConfirm"
        :handleCancel="handleCancel"
        :handleDownload="handleDownload"
        :buttons="buttons"
      />
    </slot>
  </modal>
</template>

<script>
import HccModalHeader from './HccModalHeader.vue';
import HccModalBody from './HccModalBody.vue';
import HccModalFooter from './HccModalFooter.vue';

export default {
  components: {
    HccModalHeader,
    HccModalBody,
    HccModalFooter,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    border: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: [String, Object, Array],
    },
    customClassBody: {
      type: [String, Object, Array],
    },
    buttons: {
      type: Array,
      default: () => ['cancel', 'confirm'],
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    maxWidth: {
      type: Number,
      default: 1200,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    confirmDisableButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
      this.$modal.hide(this.$props.name);
    },
    handleCancel() {
      this.$emit('cancel');
      this.$modal.hide(this.$props.name);
    },
    handleDownload() {
      this.$emit('download');
      this.$modal.hide(this.$props.name);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/modals.scss";
</style>
